@use 'sass:map';
@use '@angular/material' as mat;

@mixin internal-server-error-dialog($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  h1 {
    color: mat.get-color-from-palette($primary-palette) !important;
    font-size: 24px !important;
    font-weight: normal !important;
  }

  .mat-mdc-dialog-title {
    margin-bottom: 0 !important;
    padding-top: 16px !important;
  }

  ozgcloud-fixed-dialog .mat-mdc-dialog-title {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .mdc-dialog__title::before {
    height: 0 !important;
  }

  .mat-mdc-dialog-content .mdc-dialog__content,
  .mdc-dialog .mdc-dialog__content {
    padding-bottom: 24px !important;
  }

  .mat-mdc-dialog-actions {
    padding: 0 24px 24px 24px !important;
  }
}
