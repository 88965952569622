/**
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@import 'variables';

.l-scroll-area {
  height: calc(100vh - $header-height - $navigation-height);
  background-color: #fff;
  // TODO Wofür ist der box-shadow?
  // box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08), inset 1px 0 0 rgba(0, 0, 0, 0.08), inset -1px 0 0 rgba(0, 0, 0, 0.08);
  overflow: auto;
  padding-bottom: 40px;
  outline: 0;

  &--full {
    // TODO Wofür ist der box-shadow?
    // box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08), inset 1px 0 0 rgba(0, 0, 0, 0.08), inset -1px 0 0 rgba(0, 0, 0, 0.08);
    position: relative;
    display: block;
    min-height: calc(100vh - $header-height - $navigation-height);
    background-color: #fff;
  }
}

body.dark {
  .l-scroll-area,
  .l-scroll-area--full {
    background-color: rgb(40, 40, 40);
  }
}
