/**
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use '@angular/material' as mat;

@import 'libs/design-system/src/lib/tailwind-preset/root.css';

// FIX mdc-notched-outline conflict
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

@import 'libs/ui/src/lib/font/font_material';
@import 'typeface-roboto/index.css';

@import 'abstracts/variables';
@import 'base/setup';
@import 'layout/main';
@import 'material/autocomplete';
@import 'material/button';
@import 'material/dialog';
@import 'material/formfield';
@import 'material/icons';
@import 'material/list';
@import 'material/menu';
@import 'material/snackbar';
@import 'material/tabs';
@import 'material/typography';
@import 'libs/navigation/src/lib/header-container/header/header.theme';
@import 'libs/ui/src/lib/ui/expansion-panel/expansion-panel.theme';
@import 'libs/ui/src/lib/ui/fixed-dialog/fixed-dialog.theme';
@import 'libs/wiedervorlage/src/lib/wiedervorlage-page-container/wiedervorlage-page/wiedervorlage-breadcrumb-container/wiedervorlage-breadcrumb/wiedervorlage-breadcrumb.theme';
@import 'libs/binary-file/src/lib/binary-file-container/binary-file/binary-file.theme';
@import 'libs/postfach/src/lib/postfach-mail-form/postfach-mail-form.theme';
@import 'libs/vorgang-shared-ui/src/lib/vorgang-search-container/vorgang-search-container.theme';
@import 'libs/wiedervorlage/src/lib/wiedervorlage-list-in-vorgang-list-container/wiedervorlage-list-in-vorgang-list-container.theme';
@import 'libs/user-profile/src/lib/user-profile-in-vorgang-container/user-profile-in-vorgang/user-profile-in-vorgang.theme';
@import 'libs/user-profile/src/lib/user-profile-search-container/user-profile-search-container.theme';
@import 'libs/vorgang-shared-ui/src/lib/vorgang-nummer/vorgang-nummer.component.theme';
@import 'libs/ui/src/lib/icon/postfach-icon/postfach-icon.component.theme';
@import 'libs/ui/src/lib/ui/button-toggle/button-toogle.theme';
@import 'libs/vorgang/src/lib/vorgang-list-page-container/vorgang-list-page/vorgang-filter-menu-container/vorgang-filter-menu/_vorgang-filter-item.theme.scss';
@import 'libs/vorgang/src/lib/vorgang-list-page-container/vorgang-list-page/vorgang-views-menu/_vorgang-views-menu.theme.scss';

@include mat.all-component-typographies();
@include mat.core();

@mixin custom-components-theme($theme) {
  @include expansion-panel($theme);
  @include button($theme);
  @include breadcrumb($theme);
  @include search-container($theme);
  @include user-profile-icon($theme);
  @include internal-server-error-dialog($theme);
  @include autocomplete($theme);
}

@include mat.all-component-themes($alfaTheme);
@include custom-components-theme($alfaTheme);

body.dark {
  @include mat.all-component-colors($alfaDarkTheme);
  @include custom-components-theme($alfaDarkTheme);
}
