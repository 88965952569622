@use 'sass:map';
@use '@angular/material' as mat;
@use 'variables';

@mixin autocomplete($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .cdk-overlay-pane {
    &:before {
      content: '';
      height: 7px;
      width: 100%;
      position: absolute;
      top: -6px;
      background: inherit;
      z-index: 2;
    }
  }

  alfa-vorgang-search {
    button.mat-mdc-icon-button.mat-mdc-button-base {
      padding: 8px;
      width: 40px;
      height: 40px;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
      .mat-mdc-form-field-infix {
      padding: 0.4375em 0;
      min-height: auto;
    }
  }

  .mdc-menu-surface.mat-mdc-autocomplete-panel.vorgang-search {
    max-height: calc(100vh - $header-height);
    max-width: 600px;
    width: calc(600px - (800px - 100vw));
    height: auto;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
    position: relative;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -40px;
    padding: 0;

    .mat-mdc-option {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      font-size: 14px;
      padding: 0.5rem 1rem;
      margin-bottom: 0 !important;

      &:first-child {
        border-top: none;
      }

      .mdc-list-item__primary-text {
        flex-grow: 1;
      }

      .line .date {
        span {
          vertical-align: middle;
        }
        mat-icon {
          margin-right: 0.25rem;
          min-width: 1.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}
