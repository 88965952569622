/**
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use '@angular/material' as mat;

$grey: #777;
$greyLight: rgba(0, 0, 0, 0.08);
$darkModeGreyLight: #c2c2c2;

$background: #fafafa;
$dark-background: #303030;
// TODO Etwas wie mat.get(mat.$dark-theme-background-palette, 'card') verwenden, siehe node_modules/@angular/material/core/theming/_palette.scss
$darkBackgroundExpansionPanelLike: #424242;

$shadow-bottom: inset 0 -1px 0 rgba(#000, 0.08);
$header-height: 64px;
$navigation-width: 20px;
$navigation-height: 50px;

$activeButton: #e5eef8;
$activeButtonDark: #212121;

$primaryPalette: mat.define-palette(mat.$blue-palette, 800, 500, 900);
$accentPalette: mat.define-palette(mat.$yellow-palette, 600, 300, 800);
$warnPalette: mat.define-palette(mat.$red-palette, 800, 600, 900);

$statusNeuPalette: mat.define-palette(mat.$blue-palette, 800, 500, 900);
$statusAngenommenPalette: mat.define-palette(mat.$yellow-palette, 600, 300, 800);
$statusInBearbeitungPalette: mat.define-palette(mat.$yellow-palette, 600, 300, 800);
$statusBeschiedenPalette: mat.define-palette(mat.$green-palette, 600, 300, 800);
$statusAbgeschlossenPalette: mat.define-palette(mat.$green-palette, 600, 300, 800);
$statusVerworfenPalette: mat.define-palette(mat.$gray-palette, 600, 300, 800);

$alfaTheme: mat.define-light-theme(
  (
    color: (
      primary: $primaryPalette,
      accent: $accentPalette,
      warn: $warnPalette,
    ),
  )
);

// dark theme
$primaryDarkPalette: mat.define-palette(mat.$yellow-palette, 700, 300, 900);
$accentDarkPalette: mat.define-palette(mat.$brown-palette, 600, 300, 800);

$alfaDarkTheme: mat.define-dark-theme(
  (
    color: (
      primary: $primaryDarkPalette,
      accent: $accentDarkPalette,
      warn: $warnPalette,
    ),
  )
);

$default-font-size: 16px;

$iconHeight: 24px;
